import {
  Maybe,
  NirioBankDebtOrigin,
  NirioBankOperationType,
} from '../../api/generated';

export const retrieveOperationTypeLabel = (
  operationType?: Maybe<NirioBankOperationType>,
): string => {
  if (!operationType) {
    return '';
  }

  switch (operationType) {
    case NirioBankOperationType.CardContestationRefund:
      return 'Remboursement contestation';
    case NirioBankOperationType.CardCreationFee:
      return 'Frais de création de carte';
    case NirioBankOperationType.CardCredit:
      return 'Crédit carte';
    case NirioBankOperationType.CashDeposit:
      return "Dépôt d'espèces";
    case NirioBankOperationType.CashWithdrawal:
      return "Retrait d'espèces";
    case NirioBankOperationType.CashbackCashIn:
      return 'Cashback';
    case NirioBankOperationType.CommercialGesture:
      return 'Geste commercial';
    case NirioBankOperationType.DirectDebitFee:
      return 'Frais de prélèvement échoué';
    case NirioBankOperationType.InternalTransferIssued:
      return 'Virement interne émis';
    case NirioBankOperationType.InternalTransferReceived:
      return 'Virement interne reçu';
    case NirioBankOperationType.ExceptionalAct:
      return 'Actes exceptionnels';
    case NirioBankOperationType.NirioFee:
      return 'Frais Nirio';
    case NirioBankOperationType.OutgoingDebit:
      return 'Prélèvement entrant';
    case NirioBankOperationType.OutgoingDebitRefund:
      return 'Remboursement prélèvement entrant ';
    case NirioBankOperationType.PaymentByCard:
      return 'Paiement carte';
    case NirioBankOperationType.PaymentByCardRefund:
      return 'Remboursement paiement carte';
    case NirioBankOperationType.TopUpCard:
      return 'Chargement carte';
    case NirioBankOperationType.TransferIssued:
      return 'Virement émis';
    case NirioBankOperationType.TransferIssuedRefund:
      return 'Remboursement virement émis';
    case NirioBankOperationType.TransferReceived:
      return 'Virement reçu';
    case NirioBankOperationType.TransferReceivedRefund:
      return 'Remboursement virement reçu';
    case NirioBankOperationType.WithdrawalAtm:
      return 'Retrait DAB';
    case NirioBankOperationType.WelcomePromotionBenefit:
      return 'Promotion Bienvenue';
    case NirioBankOperationType.ReferralPromotionBenefit:
      return 'Promotion Parrainage';
    case NirioBankOperationType.RetailerPromotionBenefit:
      return 'Promotion Détaillant';

    default:
      return operationType satisfies never;
  }
};

export const nirioBankDebtOriginLabelMapping: Record<
  NirioBankDebtOrigin,
  string
> = {
  CARD_ORDER_FEE: 'Frais de commande de carte',
  CARD_PAYMENT: 'Paiement carte',
  DIRECT_DEBIT_FEE: "Facturation d'un prélèvement échoué",
  OPERATION_FEE: "Frais d'opération",
  POINT_OF_SALE_WITHDRAWAL: 'Retrait en pdv',
  SUBSCRIPTION_FEE: "Frais d'abonnement",
};

export const FEE_ORIGIN_TYPES = [
  NirioBankDebtOrigin.CardOrderFee,
  NirioBankDebtOrigin.DirectDebitFee,
  NirioBankDebtOrigin.OperationFee,
  NirioBankDebtOrigin.SubscriptionFee,
] satisfies NirioBankDebtOrigin[];

export const DEBT_ORIGIN_TYPES = [
  NirioBankDebtOrigin.CardPayment,
  NirioBankDebtOrigin.PointOfSaleWithdrawal,
] satisfies NirioBankDebtOrigin[];

// Checks that all NirioBankDebtOrigin enum values
// are included in FEE_ORIGIN_TYPES or DEBT_ORIGIN_TYPES
NirioBankDebtOrigin satisfies Record<
  keyof typeof NirioBankDebtOrigin,
  (typeof DEBT_ORIGIN_TYPES)[number] | (typeof FEE_ORIGIN_TYPES)[number]
>;
