import DownloadIcon from '@mui/icons-material/Download';
import {ReactElement} from 'react';
import {
  ArrayField,
  Button,
  Datagrid,
  DateField,
  FunctionField,
  TextField,
  useNotify,
} from 'react-admin';

import {
  NirioBankFeeStatementDto,
  NirioBankFeeStatementStatus,
  useGetFeeStatementDocumentLazyQuery,
} from '../../api/generated';
import {TableSpacer} from '../../components/CustomShow';

type Props = {
  nirioUserId: string;
};
export const NirioBankFeeStatementTable = ({
  nirioUserId,
}: Props): JSX.Element | null => {
  const [getFeeStatementDocument] = useGetFeeStatementDocumentLazyQuery();
  const notify = useNotify();

  return (
    <TableSpacer>
      <ArrayField
        perPage={5}
        source="feeStatements"
        sort={{field: 'year', order: 'DESC'}}>
        <Datagrid
          sx={{boxShadow: 'none'}}
          hover={false}
          bulkActionButtons={false}>
          <DateField source="createdAt" locales="fr-FR" showTime={true} />
          <TextField source="year" label="Année" />
          <TextField source="status" label="Status" />

          <FunctionField
            label="Document"
            render={(
              record: NirioBankFeeStatementDto,
            ): ReactElement | string => {
              if (record.status !== NirioBankFeeStatementStatus.Generated) {
                return '';
              }
              return (
                <Button
                  label="Télécharger"
                  endIcon={<DownloadIcon />}
                  onClick={(): void =>
                    void getFeeStatementDocument({
                      variables: {nirioUserId, year: record.year},
                    })
                      .then(({data}) => {
                        if (data) {
                          window.open(
                            data.getFeeStatementDocument.url,
                            '_blank',
                          );
                        }
                      })
                      .catch(() => {
                        notify('ra.notification.downloadPdfError', {
                          type: 'error',
                        });
                      })
                  }
                />
              );
            }}
          />
        </Datagrid>
      </ArrayField>
    </TableSpacer>
  );
};
